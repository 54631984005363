import React from 'react';
import { graphql } from 'gatsby';

import Layout from '/src/components/layout';
import EventList from '/src/components/eventList';
import CalendarLink from '/src/components/calendarLink';
import Head from '/src/components/head';
import FetchEvents from '/src/dao/fetchEvents';
import { H1, H2 } from '/src/components/wrapper/h';

export default ({ data, location }) => {
	const year = 2023;
	const month = 11;
	const imageDirPath = `${year}/${('0' + month).slice(-2)}`;
	const events = FetchEvents(year, month, imageDirPath);

	const siteUrl = data.site.siteMetadata.url;

	return (
		<>
			<Head>
				<title>{`${year}年${month}月お試し引換券 | たらこ`}</title>
				<meta name='description' content={`${year}年${month}月のローソンお試し引換券を紹介`} />
				<link rel='canonical' href={siteUrl + location.pathname}></link>
			</Head>

			<Layout>
				<H1 id='title'>{`お試し引換券 ${year}年${month}月`}</H1>
				<aside>
					<CalendarLink events={events} />
				</aside>
				<EventList events={events} />
			</Layout>
		</>
	);
};

export const pageQuery = graphql`
	query {
		site {
			siteMetadata {
				url
			}
		}
	}
`;
